import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

const NotFound = () => import("@/pages/GeneralViews/NotFoundPage");
const Forbidden = () => import("@/pages/GeneralViews/ForbiddenPage");
const Login = () => import("@/pages/Auth/Login.vue");
const Logout = () => import("@/pages/Auth/Logout.vue");
const GeneralScreenDashboard = () =>
  import("@/pages/Layouts/GeneralScreenDashboard.vue");
const EmptyContent = () => import("@/pages/Layouts/EmptyContent.vue");
const Dashboard = () => import("@/pages/Dashboard/index.vue");
const ReportDashboard = () => import("@/pages/Dashboard/index.vue");
const WelcomePage = () => import("@/pages/Dashboard/Welcome.vue");

const UserList = () => import("@/pages/User/List.vue");
const UserForm = () => import("@/pages/User/Form.vue");
const UserDetail = () => import("@/pages/User/Detail.vue");

const HotelList = () => import("@/pages/Hotel/List.vue");
const HotelForm = () => import("@/pages/Hotel/Form.vue");
const HotelDetail = () => import("@/pages/Hotel/Detail.vue");

const AirlinesList = () => import("@/pages/Airlines/List.vue");
const AirlinesForm = () => import("@/pages/Airlines/Form.vue");
const AirlinesDetail = () => import("@/pages/Airlines/Detail.vue");

const BankList = () => import("@/pages/Bank/List.vue");
const BankForm = () => import("@/pages/Bank/Form.vue");
const BankDetail = () => import("@/pages/Bank/Detail.vue");

const AddonList = () => import("@/pages/Addon/List.vue");
const AddonForm = () => import("@/pages/Addon/Form.vue");
const AddonDetail = () => import("@/pages/Addon/Detail.vue");

const DiscountList = () => import("@/pages/Discount/List.vue");
const DiscountForm = () => import("@/pages/Discount/Form.vue");
const DiscountDetail = () => import("@/pages/Discount/Detail.vue");

const ProductTypeList = () => import("@/pages/ProductType/List.vue");
const ProductTypeForm = () => import("@/pages/ProductType/Form.vue");
const ProductTypeDetail = () => import("@/pages/ProductType/Detail.vue");

const BranchList = () => import("@/pages/Branch/List.vue");
const BranchForm = () => import("@/pages/Branch/Form.vue");
const BranchDetail = () => import("@/pages/Branch/Detail.vue");

const AirportList = () => import("@/pages/Airport/List.vue");
const AirportForm = () => import("@/pages/Airport/Form.vue");
const AirportDetail = () => import("@/pages/Airport/Detail.vue");

const PaketList = () => import("@/pages/Paket/List.vue");
const PaketForm = () => import("@/pages/Paket/Form.vue");
const PaketDetail = () => import("@/pages/Paket/Detail.vue");

const FacilityList = () => import("@/pages/Facility/List.vue");
const FacilityForm = () => import("@/pages/Facility/Form.vue");
const FacilityDetail = () => import("@/pages/Facility/Detail.vue");

const OrderList = () => import("@/pages/Order/List.vue");
const OrderDetail = () => import("@/pages/Order/Detail.vue");
const OrderPrintPdf = () => import("@/pages/Order/components/print_pdf.vue");
const OrderForm = () => import("@/pages/Order/Form.vue");
const OrderEditForm = () => import("@/pages/Order/Form.vue");

const SettingForm = () => import("@/pages/Setting/Form.vue");

const CustomerList = () => import("@/pages/Customer/List.vue");
const CustomerDetail = () => import("@/pages/Customer/Detail.vue");

const MitraList = () => import("@/pages/Mitra/List.vue");
const MitraDetail = () => import("@/pages/Mitra/Detail.vue");
const MitraForm = () => import("@/pages/Mitra/Form.vue");

const BannerList = () => import("@/pages/Banner/List.vue");
const BannerDetail = () => import("@/pages/Banner/Detail.vue");
const BannerForm = () => import("@/pages/Banner/Form.vue");

const DzikirPagiPetangList = () => import("@/pages/DzikirPagiPetang/List.vue");
const DzikirPagiPetangDetail = () => import("@/pages/DzikirPagiPetang/Detail.vue");
const DzikirPagiPetangForm = () => import("@/pages/DzikirPagiPetang/Form.vue");

const DepatureGroup = () => import("@/pages/DepatureGroup/Index.vue")
const DepatureGroupDetail = () => import("@/pages/DepatureGroup/Detail")
const DepatureGroupList = () => import("@/pages/DepatureGroup/ListGroup.vue")

const BailoutList = () => import("@/pages/Bailout/List.vue")

const TransaksiList = () => import("@/pages/Transaksi/index");

const routes = [
  {
    path: "/logout",
    component: Logout,
    meta: { requireAuth: true },
  },
  {
    path: "/auth",
    redirect: "/auth/login",
    component: GeneralScreenDashboard,
    children: [
      {
        path: "login",
        name: "Login Account",
        component: Login,
      },
    ],
    meta: { requireGuest: true },
  },
  {
    path: "/",
    component: GeneralScreenDashboard,
    children: [
      {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        // meta: { requiredAllowedRole: ['admin', 'back office', 'customer service'] }
      },
      {
        path: "/welcome",
        name: "Empty Content Welcome Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Welcome Page",
            component: WelcomePage,
            meta: { requireAuth: true },
          },
        ],
      },
      {
        path: "/report-dashboard",
        name: "Empty Content Report Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Report Dashboard",
            component: ReportDashboard,
            meta: { requireAuth: true },
          },
        ],
      },
      {
        path: "/setting",
        name: "Empty Content Setting Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Pengaturan Akun",
            component: SettingForm,
            meta: { requireAuth: true },
          },
        ],
      },
      {
        path: "/transaksi",
        name: "Transaksi List",
        component: EmptyContent,
        redirect: "/transaksi/cek-data-transaksi",
        meta: { requiredAllowedRole: ["teller"] },
        children: [
          {
            path: "cek-data-transaksi",
            name: "Cek Data Transaksi",
            component: TransaksiList,
          },
          {
            path: "cek-data-paket",
            name: "Cek Data Paket",
            component: TransaksiList,
          },
          {
            path: "cek-data-jamaah",
            name: "Cek Data Jamaah",
            component: TransaksiList,
          },
          {
            path: "cek-data-virtual-account",
            name: "Cek Data Virtual Account",
            component: TransaksiList,
          },
        ],
      },
      {
        path: 'depature-group',
        name: 'Empty Content Depature Group Container',
        component: EmptyContent,
        children: [
          {
            path: '/',
            alias: '/',
            name: 'List Depature Group',
            component: DepatureGroupList
          },
          {
            path: 'create',
            name: 'Create Depature Group',
            component: DepatureGroup
          },
          {
            path: ':id',
            name: 'Detail Departure Group',
            component: DepatureGroupDetail
          },
          {
            path: ":id/edit",
            name: "Edit Departure Group",
            component: DepatureGroup,
          }
        ]
      },
      {
        path: "order",
        name: "Empty Content Order Container",
        component: EmptyContent,
        children: [
          {
            path: "index",
            alias: "/",
            name: "Listing Order",
            component: OrderList,
            meta: {
              requiredAllowedRole: ["admin", "back office", "customer service", "manifest"],
            },
          },
          {
            path: "create",
            alias: "/",
            name: "Tambah Order",
            component: OrderForm,
            meta: { requiredAllowedRole: ["admin", "customer service"] },
          },
          {
            path: ":id/edit",
            name: "Edit Order",
            component: OrderEditForm,
            meta: { requiredAllowedRole: ["admin", "customer service"] },
          },
          {
            path: ":id/edit",
            name: "Edit Order",
            component: OrderForm,
            meta: { requiredAllowedRole: ["admin", "customer service"] },
          },
          {
            path: ":id",
            name: "Detail Order ",
            component: OrderDetail,
            meta: {
              requiredAllowedRole: ["admin", "back office", "customer service", "manifest"],
            },
          },
          {
            path: ":id/print-pdf",
            name: "Order Print Pdf",
            component: OrderPrintPdf,
            meta: { requiredAllowedRole: ["admin", "customer service"] },
          },
        ],
      },
      {
        path: "/master-data",
        redirect: "/master-data/user",
        component: GeneralScreenDashboard,
        children: [
          {
            path: "user",
            name: "Empty Content User Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing User",
                component: UserList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data User",
                component: UserForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data User",
                component: UserDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data User",
                component: UserForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "hotel",
            name: "Empty Content Hotel Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Hotel",
                component: HotelList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Hotel",
                component: HotelForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Hotel",
                component: HotelDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Hotel",
                component: HotelForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "bailout",
            name: "Empty Content Hotel Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Bailout",
                component: BailoutList,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "airlines",
            name: "Empty Content Airlines Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Airlines",
                component: AirlinesList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Airlines",
                component: AirlinesForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Airlines",
                component: AirlinesDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Airlines",
                component: AirlinesForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "bank",
            name: "Empty Content Bank Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Bank",
                component: BankList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Bank",
                component: BankForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Bank",
                component: BankDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Bank",
                component: BankForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "addon",
            name: "Empty Content Addon Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Addon",
                component: AddonList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Addon",
                component: AddonForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Addon",
                component: AddonDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Addon",
                component: AddonForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "discount",
            name: "Empty Content Discount Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Discount",
                component: DiscountList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Discount",
                component: DiscountForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Discount",
                component: DiscountDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Discount",
                component: DiscountForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "product-type",
            name: "Empty Content Product Type Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing ProductType",
                component: ProductTypeList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data ProductType",
                component: ProductTypeForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data ProductType",
                component: ProductTypeDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data ProductType",
                component: ProductTypeForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "branch",
            name: "Empty Content Branch Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Branch",
                component: BranchList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Branch",
                component: BranchForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Branch",
                component: BranchDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Branch",
                component: BranchForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "airport",
            name: "Empty Content Airport Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Airport",
                component: AirportList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Airport",
                component: AirportForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Airport",
                component: AirportDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Airport",
                component: AirportForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "Paket",
            name: "Empty Content Paket Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Paket",
                component: PaketList,
                meta: { requiredAllowedRole: ["admin", "customer service"] },
              },
              {
                path: "create",
                name: "Tambah Data Paket",
                component: PaketForm,
                meta: { requiredAllowedRole: ["admin", "customer service"] },
              },
              {
                path: ":id",
                name: "Detail Data Paket",
                component: PaketDetail,
                meta: { requiredAllowedRole: ["admin", "customer service"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Paket",
                component: PaketForm,
                meta: { requiredAllowedRole: ["admin", "customer service"] },
              },
            ],
          },
          {
            path: "Facility",
            name: "Empty Content Facility Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Facility",
                component: FacilityList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Tambah Data Fasilitas",
                component: FacilityForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Fasilitas",
                component: FacilityDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Ubah Data Fasilitas",
                component: FacilityForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "customer",
            name: "Empty Content Customer Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Jamaah",
                component: CustomerList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Customer",
                component: CustomerDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "mitra",
            name: "Empty Content Mitra Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Mitra",
                component: MitraList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Create Data Mitra",
                component: MitraForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Edit Data Mitra",
                component: MitraForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Mitra",
                component: MitraDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "banner",
            name: "Empty Content Banner Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Banner",
                component: BannerList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Create Data Banner",
                component: BannerForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Edit Data Banner",
                component: BannerForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Banner",
                component: BannerDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
          {
            path: "dzikir-pagi-petang",
            name: "Empty Content Dzikir Pagi Petang Container",
            component: EmptyContent,
            children: [
              {
                path: "index",
                alias: "/",
                name: "Listing Dzikir Pagi Petang",
                component: DzikirPagiPetangList,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: "create",
                name: "Create Data Dzikir Pagi Petang",
                component: DzikirPagiPetangForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id/edit",
                name: "Edit Data Dzikir Pagi Petang",
                component: DzikirPagiPetangForm,
                meta: { requiredAllowedRole: ["admin"] },
              },
              {
                path: ":id",
                name: "Detail Data Dzikir Pagi Petang",
                component: DzikirPagiPetangDetail,
                meta: { requiredAllowedRole: ["admin"] },
              },
            ],
          },
        ],
      },
    ],
    meta: { requireAuth: true },
  },
  {
    path: "/403",
    component: Forbidden,
  },
  {
    path: "*",
    component: NotFound,
  },
];

Vue.use(VueRouter);
const router = new VueRouter({
  routes, // short for router: router
  linkActiveClass: "active",
});

if (store.getters["account/isLoggedIn"]) {
  store.dispatch("account/saveTokens", {
    tokens: store.getters["account/tokens"],
    accountData: null,
    isLocalLogin: true,
  });
}
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // if (from.path.split("/")[1]) {
    //   if (store.getters["account/accountData"].role != "admin") {
    //     next({
    //       path: "/",
    //       query: { redirect: to.fullPath },
    //     });
    //   }
    // }
    if (!store.getters["account/isLoggedIn"]) {
      next({
        path: "/auth/login",
        query: { redirect: to.fullPath },
      });
    } else if (
      to.meta.requiredAllowedRole !== undefined &&
      to.meta.requiredAllowedRole.length > 0 &&
      to.meta.requiredAllowedRole.filter(
        (role) => role === store.getters["account/accountData"].role
      ).length < 1
    ) {
      next({
        path: "/logout",
      });
    } 
    else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.requireGuest)) {
    if (store.getters["account/isLoggedIn"]) {
      next({
        path: "/",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
