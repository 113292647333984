<template>
  <div v-if="isLoading === false">
    <router-view></router-view>
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
// import { initFacebookSdk } from './_helpers'
export default {
  data() {
    return {
      isLoading: false,
    };
  },
  mounted() {
    // wait for facebook sdk to start app
    // initFacebookSdk().then(() => {
    //   this.isLoading = false
    // })
  },
};
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
<style src="../node_modules/izitoast/dist/css/iziToast.min.css"></style>
<style src="../node_modules/element-ui/lib/theme-chalk/switch.css"></style>
<style src="../node_modules/element-ui/lib/theme-chalk/table.css"></style>
<style
  src="../node_modules/element-ui/lib/theme-chalk/table-column.css"
></style>
<!-- <style src='../node_modules/sweetalert2/dist/sweetalert2.min.css'></style> -->
