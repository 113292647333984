import axios from 'axios'
import { initializeApp } from 'firebase/app';
// import firebase from '@firebase/app'
import '@firebase/messaging'
import router from '@/router'

// const parser = require('ua-parser-js')
const accessTokenStorageName = 'access_token'
const refreshTokenStorageName = 'refresh_token'
const accountDataStorageName = 'account_data'

export default {
  state: {
    httpInterceptor: null,
    responseInterceptor: null,
    accessToken: localStorage.getItem(accessTokenStorageName),
    refreshToken: localStorage.getItem(refreshTokenStorageName),
    accountData: localStorage.getItem(accountDataStorageName),
    isOffline: false,
    firebaseMessaging: null
  },
  getters: {
    tokens: state => {
      return {
        access_token: state.accessToken,
        refresh_token: state.refreshToken
      }
    },
    accessToken: state => {
      return state.accessToken
    },
    refreshToken: state => {
      return state.refreshToken
    },
    accessTokenHeader: state => {
      return 'Bearer ' + state.accessToken
    },
    isLoggedIn: state => {
      return state.accessToken !== null
    },
    accountData: state => {
      return JSON.parse(state.accountData)
    },
    permissions: state => {
      const a = JSON.parse(state.accountData)
      if (a.role !== null && a.role.permissions !== undefined) {
        return a.role.permissions
      } else {
        return null
      }
    },
    needPermission: (state, getters) => (keyword) => {
      if (getters.permissions !== null) {
        const permission = getters.permissions.filter((m) => {
          return m === keyword
        })
        return !!permission.length
      } else {
        return false
      }
    },
    isOffline: state => {
      return state.isOffline
    },
    firebaseMessaging: state => {
      return state.firebaseMessaging
    }
  },
  mutations: {
    setHttpInterceptor (state, data) {
      state.httpInterceptor = {
        request: data.request,
        response: data.response
      }
    },
    login (state, tokens) {
      state.accessToken = tokens.accessToken
      state.refreshToken = tokens.refreshToken
      if (!tokens.account_data !== null) {
        state.accountData = tokens.accountData
      }
    },
    logout (state) {
      state.httpInterceptor = {
        request: null,
        response: null
      }
      state.accessToken = null
      state.refreshToken = null
      state.accountData = null
    },
    checkIsOffline (state, status) {
      state.isOffline = status
    }
  },
  actions: {
    login ({ dispatch }, credentials) {
      return axios.post(process.env.VUE_APP_API_BASE_URL + 'oauth/token', {
        username: credentials.username,
        password: credentials.password,
        grant_type: 'password',
        client_id: process.env.VUE_APP_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_CLIENT_SECRET
      }).then(response => {
        const a = response.data
        return dispatch('getAccountData', a.access_token).then((response) => {
          return dispatch('saveTokens', {tokens: a, accountData: JSON.stringify(response), isLocalLogin: false})
        }).catch(error => {
          return Promise.reject(error)
        })
      })
      .catch(error => {
        return Promise.reject(error)
      })
    },
    localLogin () {
      return {
        data: {
          accessToken: localStorage.getItem(accessTokenStorageName),
          refreshToken: localStorage.getItem(refreshTokenStorageName)
        }
      }
    },
    refreshToken ({ state }) {
      return axios.post(process.env.VUE_APP_API_BASE_URL + 'oauth/token', {
        grant_type: 'refresh_token',
        client_id: process.env.VUE_APP_API_CLIENT_ID,
        client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
        refresh_token: state.refreshToken
      }).then(response => {
        return response.data
      })
        .catch(error => {
          return Promise.reject(error)
        })
    },
    logout ({ commit, state, dispatch }) {
      if (process.env.VUE_APP_ENABLE_NOTIFICATION === '1') {
        dispatch('clearFirebase')
      }
      localStorage.removeItem(accountDataStorageName)
      return axios.delete(process.env.VUE_APP_API_BASE_URL + 'api/oauth/token').then(() => {
        axios.interceptors.request.eject(state.httpInterceptor.request)
        axios.interceptors.response.eject(state.httpInterceptor.response)
        localStorage.removeItem(accessTokenStorageName)
        localStorage.removeItem(refreshTokenStorageName)
        commit('logout')
        // return dispatch('facebook/logout', null, { root: true }).then(() => {
        //   commit('logout')
        // })
      }).catch(() => {
        localStorage.removeItem(accessTokenStorageName)
        localStorage.removeItem(refreshTokenStorageName)
        commit('logout')
      })
    },
    saveTokens ({ state, commit, dispatch }, params) {
      let a = params.tokens.access_token
      let b = params.tokens.refresh_token
      const c = params.accountData === null ? state.accountData : params.accountData
      if (!params.isLocalLogin) {
        localStorage.setItem(accessTokenStorageName, a)
        localStorage.setItem(refreshTokenStorageName, b)
        localStorage.setItem(accountDataStorageName, c)
      }
      commit('login', {
        accessToken: a,
        refreshToken: b,
        accountData: c
      })
      // set default timezone based on Account Data
      // moment.tz.setDefault(getters.accountData.timezone)
      const requestInterceptors = axios.interceptors.request.use(config => {
        dispatch('setIsOffline', false)
        if (state.accessToken !== null) {
          config.headers.Authorization = 'Bearer ' + state.accessToken
        }
        return config
      })
      if (process.env.VUE_APP_ENABLE_NOTIFICATION === '1') {
        dispatch('initFirebase')
      }
      // if (parser().device.type != 'mobile') {
      //   dispatch('initFirebase')
      // }
      const responseInterceptors = axios.interceptors.response.use(undefined, function (err) {
        if (err.response === undefined) {
          if (err.message === 'Network Error') dispatch('setIsOffline', true)
        } else {
          dispatch('setIsOffline', false)
          if (err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
            return new Promise(function (resolve, reject) {
              return dispatch('refreshToken').then(response => {
                localStorage.setItem(accessTokenStorageName, response.access_token)
                localStorage.setItem(refreshTokenStorageName, response.refresh_token)
                commit('login', {
                  accessToken: response.access_token,
                  refreshToken: response.refresh_token,
                  accountData: state.accountData
                })
                err.config.__isRetryRequest = true
                // err.config.headers.Authorization = 'Bearer ' + response.access_token
                axios(err.config).then(resolve, reject)
              }).catch(() => {
                return router.push('/logout')
                // dispatch('logout')
                // Promise.reject(null)
              })
            })
          } else {
            return Promise.reject(err)
          }
        }
      })
      commit('setHttpInterceptor', {
        request: requestInterceptors,
        response: responseInterceptors
      })
      // if (getters.accountData !== null && getters.accountData.role !== 'service.admin') {
      //   return Promise.reject('I\'m so sorry, You don\'t have permission to access on this website.')
      // }
      return responseInterceptors
    },
    // eslint-disable-next-line
    getAccountData ({ state }, accessToken) {
      return axios.get(process.env.VUE_APP_API_BASE_URL + 'api/me', {headers: { Authorization: 'Bearer ' + accessToken }}).then(response => {
        return response.data.data
      })
      .catch(error => {
        return Promise.reject(error)
      })
    },
    initFirebase ({
      state,
      dispatch
    }) {
      if ('serviceWorker' in navigator) {
        if (!firebase.apps.length) {
          firebase.initializeApp({
            apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
            authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
            appId: process.env.VUE_APP_FIREBASE_APP_ID,
            projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
            storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID
          })
          state.firebaseMessaging = firebase.messaging()
          state.firebaseMessaging.usePublicVapidKey(process.env.VUE_APP_FIREBASE_MESSAGING_VAPID_KEY)

          navigator.serviceWorker.register('/assets/js/firebase-messaging-sw.js?baseUrl=' + window.location.origin).then((registration) => {
            state.firebaseMessaging.useServiceWorker(registration)
            state.firebaseMessaging.requestPermission().then(() => {
              state.firebaseMessaging.getToken().then((currentToken) => {
                if (currentToken) {
                  return axios.patch(process.env.VUE_APP_API_URL + 'me/fcm-token', {
                    fcm_token: currentToken
                  }).then(() => {
                  }).catch(() => {
                    dispatch('notification/error', 'Can\'t save notif token.', { root: true })
                  })
                }
              })
            }).catch(() => {
              dispatch('notification/error', 'Please Allow Notification Permission.', { root: true })
              // alert('Please Allow Notification Permission')
            })
          })
        } else {
          state.firebaseMessaging.requestPermission().then(() => {
            state.firebaseMessaging.getToken().then((currentToken) => {
              if (currentToken) {
                return axios.patch(process.env.VUE_APP_API_URL + 'me/fcm-token', {
                  fcm_token: currentToken
                }).then(() => {
                }).catch(() => {
                  dispatch('notification/error', 'Cannot save notif token.', { root: true })
                })
              }
            })
          })
        }
        state.firebaseMessaging.onTokenRefresh(() => {
          state.firebaseMessaging.getToken().then(refreshedToken => {
            return axios.patch(process.env.VUE_APP_API_URL + 'me/fcm-token', {
              fcm_token: refreshedToken
            }).then(() => {
            }).catch(() => {
              dispatch('notification/error', 'Cannot save notif token.', { root: true })
            })
          })
        })
      } else {
        dispatch('notification/error', 'This browser not supported Notification.', { root: true })
      }
    },
    clearFirebase ({
      state
    }) {
      if ('serviceWorker' in navigator && state.firebaseMessaging !== null) {
        state.firebaseMessaging.getToken().then((currentToken) => {
          if (currentToken) {
            state.firebaseMessaging.deleteToken(currentToken)
          }
        })
      }
    },
    setIsOffline (
      {commit, dispatch}, status
    ) {
      commit('checkIsOffline', status)
      if (status) {
        dispatch('notification/error', 'Koneksi putus, Silahkan cek koneksi internet anda.', { root: true })
      }
    }
  }
}
