import moment from 'moment-timezone'
import swal from 'sweetalert2'

export function scrollToError (refElement) {
  const errors = Object.entries(refElement.errors).map(([key, value]) => ({ key, value })).filter(error => error["value"].length)
  setTimeout(() => {
    refElement.refs[errors[0]["key"]].$el.scrollIntoView({
      behavior: "smooth",
      block: "center"
    });
  }, 300)
}

export function objectToArray (obj) {
  return Object.keys(obj).map((key) => obj[key])
}

export function objectToOptions (obj) {
  return Object.keys(obj).map((key) => {
    return {
      value: key,
      text: obj[key]
    }
  })
}

export function getLastUrlSegment (thePath) {
  return thePath.substring(thePath.lastIndexOf('/') + 1)
}

export function savePengadaanProcess (model, editMode = false) {
  return new Promise(function (resolve, reject) {
    return swal.fire({
      title: 'Anda yakin akan menyimpan data ini?',
      text: 'Pastikan semua data telah benar',
      icon: 'warning',
      showDenyButton: !editMode,
      showCancelButton: true,
      confirmButtonText: !editMode ? 'Lanjutkan Step' : 'Simpan',
      denyButtonText: `Draft`,
      cancelButtonText: `Batal`,
    }).then(result => {
      if (result.isDismissed) {
        reject(new Error('dismiss'))
      }
      if (typeof model.ke_step_selanjutnya !== 'undefined') {
        if (!editMode && result.isConfirmed) {
          model.ke_step_selanjutnya.value = 'ya'
        } else {
          model.ke_step_selanjutnya.value = null
        }
      }
      model = serializeModel(model)
      return resolve(model)
    })
  })
}

export function saveImportPengadaanProcess (model) {
  return new Promise(function (resolve, reject) {
    return swal.fire({
      title: 'Anda yakin akan menyimpan data ini?',
      text: 'Pastikan semua data telah benar',
      icon: 'warning',
      showCancelButton: true,
      // confirmButtonText: !editMode ? 'Simpan Data' : 'Simpan',
      confirmButtonText: 'Simpan',
      cancelButtonText: `Batal`,
    }).then(result => {
      if (result.isDismissed) {
        reject(new Error('dismiss'))
      }
      model = serializeModel(model)
      return resolve(model)
    })
  })
}


export function saveProcess (model, editMode = false) {
  return new Promise(function (resolve, reject) {
    return swal.fire({
      title: 'Anda yakin akan menyimpan data ini?',
      text: 'Pastikan semua data telah benar',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonText: 'Simpan',
    }).then(result => {
      if (result.isDismissed) {
        reject(new Error('dismiss'))
      }
      model = serializeModel(model)
      return resolve(model)
    })
  })
}

export function serializeModel (model) {
  let data = {}
  Object.keys(model).forEach(key => {
    if (typeof model[key].disabled === 'undefined' || !model[key].disabled) {
      if (model[key].value !== null && model[key].type === 'attachment' && model[key].type === 'attachmentExcel') {
        data[key] = model[key].value
      } else if (model[key].value !== null){
        if (model[key].value !== null) {
          if (model[key].type === 'price') {
            data[key] = cleanIdrFormat(model[key].value)
          } else if (model[key].type === 'date') {
            if (model[key].value !== null && model[key].value.includes('-')) {
              // const v = model[key].value.split('-')
              // data[key] = v[2] + '-' + v[1] + '-' + v[0]
              data[key] = model[key].value
            } else {
              data[key] = null
            }
          } else {
            if (typeof model[key].value === 'boolean') {
              data[key] = model[key].value ? 1 : 0
            } else if (typeof model[key].value === 'number') {
              data[key] = model[key].value
            } else if (typeof model[key].value === 'object') {
              data[key] = model[key].value
            } else if (model[key].value.trim() !== ''){
              data[key] = model[key].value
            }
          }
        }
      }
    }
  })
  return data
}

export function disableModels (model, excepts) {
  Object.keys(model).forEach(key => {
    if (excepts.includes(key)) {
      model[key].disabled = true
      model[key].rules = null
    }
  })
}

export function cleanTextHtml (text) {
  return text.replace(/<[^>]*>?/gm, '');
}

export function readonlyModels (model, excepts) {
  Object.keys(model).forEach(key => {
    if (excepts.includes(key)) {
      model[key].readonly = true
      model[key].rules = null
    }
  })
}

export function disableModelsExcept (model, excepts) {
  Object.keys(model).forEach(key => {
    if (!excepts.includes(key)) {
      model[key].disabled = true
      model[key].rules = null
    }
  })
}

export function readonlyModelsExcept (model, excepts) {
  Object.keys(model).forEach(key => {
    if (!excepts.includes(key)) {
      model[key].readonly = true
      model[key].rules = null
    }
  })
}

export function hiddenModelsExcept (model, excepts) {
  Object.keys(model).forEach(key => {
    if (!excepts.includes(key)) {
      model[key].disabled = true
      model[key].type = 'hidden'
      model[key].rules = null
    }
  })
}

export function hiddenModels (model, excepts) {
  Object.keys(model).forEach(key => {
    if (excepts.includes(key)) {
      model[key].disabled = true
      model[key].type = 'hidden'
      model[key].rules = null
    }
  })
}

export function setModelValue (model, value) {
  Object.keys(model).forEach(key => {
    if (typeof value[key] !== 'undefined' && value[key] !== null) {
      if (typeof value[key] === 'object' || typeof value[key] === 'array') {
        model[key].value = value[key]
      } else if (typeof value[key] === 'number') {
        model[key].value = value[key]+ ''
      } else {
        if (typeof value[key] === 'boolean') {
          model[key].value = value[key] ? 1 : 0
        } else if (String(value[key].trim()) !== ''){
          model[key].value = formatModelValue(model[key].type, value[key])
        }
      }
    }
  })
  return model
}


export function formatModelValue (type, value) {
  if (type === 'price') {
    return 'Rp. ' + new Intl.NumberFormat('id-ID').format(value) 
  } else if (type === 'date') {
    return moment(value).format('YYYY-MM-DD') 
  } else if (type === 'month'){
    return moment(value).format('YYYY-MM')
  } else if (type === 'time') {
    return moment(value).format('HH:ss') 
  } else {
    return value
  }
}

export function clearModelValue (model) {
  Object.keys(model).forEach(key => {
    model[key].value = null
  })
}

export function isRequired (rules, separator = '|') {
  return rules !== null ? rules.split(separator).includes('required') : false
}

export function serializeErrorMessage (error) {
  if (process.env.NODE_ENV !== 'production') {
    console.error(error)
  }
  if (typeof error.response !== 'undefined') {
    if (typeof error.response.data.errors !== 'undefined') {
      let validationMessages = error.response.data.errors
      if (typeof validationMessages === 'string') {
        return validationMessages
      } else if (Object.keys(validationMessages).length) {
        let errorMessages = '<ul>'
        for (let i = 0; i < Object.keys(validationMessages).length; i++) {
          errorMessages = errorMessages + '<li>' + validationMessages[Object.keys(validationMessages)[i]] + '</li>'
        }
        errorMessages = errorMessages + '</ul>'
        return errorMessages
      } else {
        return error.response.data
      }
    } else if (typeof error.response.data.message !== 'undefined') {
      return error.response.data.message === 'The user credentials were incorrect.' || error.response.data.message === 'Client authentication failed' ? 'Kombinasi email/username & password salah' : error.response.data.message
    } else if (typeof error.response.data !== 'undefined') {
      return error.response.data
    }
  } else if (typeof error === 'string') {
    return error
  } else {
    return 'ada yang salah, silahkan coba kembali.'
  }
}

export function goBack (self) {
  if (self.$route.query.redirect !== undefined) {
    self.$router.push(self.$route.query.redirect)
  } else {
    window.history.back()
  }
}

export function cleanIdrFormat (value) {
  return value !== null && value !== '' ? value.toString().replace(/\./g,'').toString().replace(/,/g,'.') : 0
}

export function formatIdrInput (value) {
  return new Promise((resolve) => {
    let cleanValue = value.replace(/\./g,'')
    // cleanValue = cleanValue.replace(/,/g,'.')
    let replaced = ''
    for (let i = 0; i < cleanValue.length; i++) {
      const a = cleanValue.charAt(i)
      if (/^([0-9.,]+)$/.test(a)) {
        replaced += a
      }
    }
    let number
    let decimal = null
    if (replaced.includes(',')) {
      const replacedArr = replaced.split(',')
      number = replacedArr[0]
      decimal = replacedArr[1]
    } else {
      number = replaced
    }

    let indexaddPoint = []
    let countDot = 0
    for (let i = number.length - 1; i >= 0 ; i--) {
      countDot++
      if (i > 0 && countDot === 3 && number.length > 3) {
        indexaddPoint.push(i)
        countDot = 0
      }
    }
    let newReplaced = ''
    for (let i = 0; i < number.length; i++) {
      if (indexaddPoint.includes(i)) {
        newReplaced += '.'
      }
      newReplaced += number.charAt(i)
    }
    return resolve(newReplaced + (decimal !== null ? ',' + decimal : ''))
  })
}

export function getYears () {
  const today = new Date()
  let y = parseInt(today.getFullYear())
  let years = {}
  for(let i = 2020; i <= y; i++) {
    years[i] = i
  }
  return years
}