import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import Cleave from 'cleave.js';
import axios from 'axios'
import infiniteScroll from 'vue-infinite-scroll'
import Vue2TouchEvents from 'vue2-touch-events'
import router from './router'
import moment from 'moment-timezone'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import '@trevoreyre/autocomplete-vue/dist/style.css'
import swal from 'sweetalert2'
import './vee-validate'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VueBottomSheet from "@webzlodimir/vue-bottom-sheet";
// import 'es6-promise/auto'
import store from './store'
import { Table, TableColumn, Switch, Input } from 'element-ui';
// import { initFacebookSdk } from './_helpers'
import ToggleButton   from 'vue-js-toggle-button'
import { library } from '@fortawesome/fontawesome-svg-core'
import VueRadioToggleButtons from 'vue-radio-toggle-buttons';
import { faSignOutAlt, faSpinner, faChevronLeft, faChevronRight, faEdit, faPlus, faEye, faSearch, faTimes, faSave, faTrash, faExternalLinkAlt, faShoppingCart, faGamepad, faWallet, faUserSecret, faBars, faCircle, faUsers, faCashRegister, faCog, faImages, faClipboard, faInfoCircle, faImage, faFileAlt, faSearchPlus, faCreditCard, faEyeSlash, faArchive, faCopyright, faSort, faCubes, faCheck, faStar, faTruck, faFileExcel, faTags, faBell, faUser, faUserCircle, faQuestion, faTint, faBorderStyle, faMedal, faClock, faChartLine, faSortUp, faSortDown, faSyncAlt, faInfo, faPencilAlt, faCalendar, faArrowLeft, faHeart, faBuilding, faStarHalf, faStarHalfAlt, faArrowRight, faEnvelope } from '@fortawesome/free-solid-svg-icons'
// import config from '@/admin-config.json'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles

library.add(faSignOutAlt, faSpinner, faChevronLeft, faChevronRight, faEdit, faPlus, faEye, faSearch, faTimes, faSave, faTrash, faExternalLinkAlt, faShoppingCart, faGamepad, faWallet, faUserSecret, faBars, faCircle, faUsers, faCashRegister, faCog, faImages, faImage, faClipboard, faInfoCircle, faFileAlt, faSearchPlus, faCreditCard, faEye, faEyeSlash, faArchive, faCopyright, faSort, faCubes, faCheck, faStar, faTruck, faFileExcel, faTags, faBell, faUser, faUserCircle, faQuestion, faTint, faBorderStyle, faMedal, faClock, faChartLine, faSortUp, faSortDown, faSyncAlt, faInfo, faPencilAlt, faTrash, faCalendar, faArrowLeft, faHeart, faBuilding, faStarHalf, faStarHalfAlt, faArrowRight, faEnvelope, faCog)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.config.productionTip = false
Vue.use(VueBottomSheet)

Vue.use(Vuesax)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(IconsPlugin)
Vue.use(Vue2TouchEvents)
Vue.use(VueRadioToggleButtons)
// Vue.use(Loading)
Vue.use(ToggleButton)
Vue.use(Autocomplete)
Vue.use(infiniteScroll)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Switch)
moment.tz.setDefault('Asia/Jakarta')
moment.locale('id-ID')
// Vue.prototype.$webConfig = config
Vue.prototype.$moment = moment
Vue.prototype.$http = axios
Vue.prototype.$swal = swal
Vue.prototype.$formatPrice = (value) => {
  if (value === null) {
    return 0
  }
  return new Intl.NumberFormat('id-ID', {minimumFractionDigits: (value % 1 > 0 ? 2 : 0)}).format(value)
}

Vue.prototype.$capitalizeFirstLetter = (string) => {
  return string === null ? '' : string.charAt(0).toUpperCase() + string.slice(1);
}

Vue.directive('cleave', {
  inserted: (el, binding) => {
      el.cleave = new Cleave(el, binding.value || {})
  },
  update: (el) => {
      const event = new Event('input', {bubbles: true});
      setTimeout(function () {
          el.value = el.cleave.properties.result
          el.dispatchEvent(event)
      }, 100);
  }
})

Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  // alert(err)
}

extend('minmax', {
  validate: function (value, { min, max }) {
    return value.length >= min && value.length <= max
  }, params: ['min', 'max']
})

setTimeout(() => {
  new Vue({
    render: h => h(App),
    router,
    store
  }).$mount('#app')
}, 500)